body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.docx-container .docx {
  padding: 10pt; /* Reduce padding */
  width: auto; /* Make width responsive */
  min-height: auto; /* Let height adapt */
  transform: scale(0.8); /* Adjust scaling */
  transform-origin: top left; /* Keep scaling origin */
}

.docx-container .docx * {
  font-size: 12pt; /* Adjust font size for readability */
  line-height: 1.5; /* Improve line spacing */
  margin: 0; /* Reset margins */
  padding: 0; /* Reset padding */
}

.docx-container {
  overflow-y: auto; /* Ensure scrolling */
  max-height: 100%; /* Prevent overflow */
  background-color: white; /* Keep clean background */
}